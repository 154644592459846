import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import login from './modules/login'
import sign from './modules/sign'
import news from './modules/news.js'
import pickup from './modules/pickup.js'
import user from './modules/user'
import common from './modules/common'
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    login,
    sign,
    news,
    pickup,
    user,
    common,
    getters,
  },
  getters
});

export default store
