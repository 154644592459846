<template>
  <div style="min-width: 769px;">
    <div class="pcHeader">
      <el-row :gutter="0">
        <el-col :xs="3" :sm="4" :md="4" :lg="5" :xl="8">
          <label class="pcHeadrTitle">{{this.$store.state.app.title}}</label>
        </el-col>
        <el-col :xs="21" :sm="19" :md="19" :lg="12" :xl="9">
          <div style="text-align: left;min-width: 700px;">
            <el-row :gutter="20">
              <el-col :span="5" class="headerImgDiv">
                <div style="float: left;"><img class="img" :src="img1" @click="choiceState('home')" /></div>
                <div style="float: left;margin-left: 66px;" @click="choiceState('home')">ホーム</div>
              </el-col>
              <el-col :span="6" class="headerImgDiv">
                <div style="float: left;"><img class="img" :src="img2" @click="choiceState('pickup')" /></div>
                <div style="float: left;margin-left:66px;" @click="choiceState('pickup')">集荷依頼</div>
              </el-col>
              <el-col :span="7" class="headerImgDiv">
                <div style="float: left;"><img class="img" :src="img3" @click="choiceState('pickupList')" /></div>
                <div style="float: left;margin-left:66px;" @click="choiceState('pickupList')">集荷依頼一覧</div>
              </el-col>
              <el-col :span="6" class="headerImgDiv">
                <div style="float: left;"><img class="img" :src="img4" @click="choiceState('systemSetting')" /></div>
                <div style="float: left;margin-left:66px;" @click="choiceState('systemSetting')">設定</div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :md="1" :lg="2" :xl="7" style="text-align: right;float: right;width:300px;"
          v-html="welcome">
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import img1Url from "../../assets/images/sonoriku/SP_footer_notxt_icon01.png"
  import img2Url from "../../assets/images/sonoriku/SP_footer_notxt_icon02.png"
  import img3Url from "../../assets/images/sonoriku/SP_footer_notxt_icon03.png"
  import img4Url from "../../assets/images/sonoriku/SP_footer_notxt_icon04.png"
  export default {
    name: "Header-menu-pc",
    data() {
      return {
        welcome: '',
        img1: img1Url,
        img2: img2Url,
        img3: img3Url,
        img4: img4Url
      }
    },
    computed: {},
    created() {
      const company = this.$store.state.app.company
      const department = this.$store.state.app.department
      if (company === '' || company === null) {
        this.welcome = 'こんにちは、' + localStorage.getItem('userNameInfo') + ' 様'
      } else {
        this.welcome = 'こんにちは、' + company + department + ' 様'
      }
    },
    methods: {
      choiceState(path) {
        switch (path) {
          case 'home':
            this.$store.commit('updateAppTitle', 'ソノリク集荷・配送サービス')
            break;
          case 'pickup':
            const pickupPostData = {
              requestDay: '', //登録日
              pickupDay: '', //集荷希望日
              deliveryDay: '', //配送希望日
              pickupToId: '', //集荷先
              pickupToName: '', //集荷先
              pickupToAddress: '', //集荷先住所
              pickupToTelNo: '', //集荷先電話番号
              deliveryToId: '', //配送先
              deliveryToName: '', //配送先
              deliveryToAddress: '', //配送先住所
              deliveryToTelNo: '', //配送先電話番号
              billingDestimation: '1', //請求先
              itemList: [], //商品リスト,
              shipperName: '' //出荷名義
            }
            this.$store.commit('updatePickPostData', pickupPostData)
            this.$store.commit('updateAppTitle', '集荷依頼')
            this.$store.dispatch('getPickupListAction')
            this.$store.dispatch('getDeliveryListAction')
            this.$store.dispatch('getGoodsListAction')
            break;
          case 'pickupList':
            this.$store.commit('updateAppTitle', '集荷依頼一覧')
            break;
          case 'systemSetting':
            this.$store.commit('updateAppTitle', '設定')
            break;
        }
        this.$router.push(path)
      },
    }
  }
</script>
<style scoped>
  .headerImgDiv {
    text-align: center;
    position: relative;
    height: 80px;
    cursor: pointer
  }

  .leftText {
    font-weight: 500;
    font-size: 22px;
  }

  .rightText {
    font-weight: 400;
    font-size: 22px;
    margin-right: 5%;
    text-align: right;
  }

  .el-row {
    margin-top: 0px !important;
    text-align: left !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .img {
    float: left;
    width: 56px;
    height: 35px;
    position: absolute;
    top: 50%;
    transform: translate(-0%, -50%);
  }

  span {
    color: white;
  }
</style>
