import {
  default as request
} from '../../utils/request'
import api from '../api'

const user = {
  state: {
    userDetails: {
      companyName: '',
      departmentName: '',
      postNo: '',
      address1: '',
      address2: '',
      staff: '',
      telNo: '',
      mail: ''
    }
  },
  mutations: {
    updateUserDetails(state, data) {
      state.userDetails.companyName = data.companyName
      state.userDetails.departmentName = data.departmentName
      state.userDetails.postNo = data.postNo
      state.userDetails.address1 = data.address1
      state.userDetails.address2 = data.address2
      state.userDetails.staff = data.staff
      state.userDetails.telNo = data.telNo
      state.userDetails.mail = data.mail
    }
  },
  actions: {
    getUserDetailsAction({
      commit
    }) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AU05,
          method: "get"
        }).then(res => {
          commit('updateUserDetails', res.data.data.data)
          resolve(data);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    changePasswordAction({
      commit
    },paramsData) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AU02,
          method: "post",
          params:paramsData
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    mailSendAction({
      commit
    },paramsData) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AU03,
          method: "post",
          params:paramsData
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
    
    resetPasswordAction({
      commit
    },paramsData) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AU04,
          method: "post",
          params:paramsData
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
  }
}
export default user
