import {
  default as request
} from '../../utils/request'
import api from '../api'

const login = {
  state: {},
  mutations: {},
  actions: {
    // ログイン
    Login({
      commit,
      state
    }, loginForm) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AU01,
          method: "post",
          data: loginForm,
          headers: {
            'content-type': 'application/json'
          }
        }).then(res => {
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem('userNameInfo',res.data.data.customerName+ res.data.data.department)
          commit('updateUserInfo', {
            company: res.data.data.customerName,
            department: res.data.data.department
          })
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    }
  }
}
export default login
