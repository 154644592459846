import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/components/layout/Layout.vue'
Vue.use(Router);

export const routerMap = [{
  path: '/',
  component: layout,
  redirect: '/home',
  name: 'home',
  hidden: true,
  children: [{
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/login'),
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home/home'),
    },
    {
      path: '/passwordUpdate',
      name: 'passwordUpdate',
      component: () => import('@/views/passwordSetting/passwordUpdate'),
    },
    {
      path: '/mailSend',
      name: 'mailSend',
      component: () => import('@/views/passwordSetting/mailSend'),
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: () => import('@/views/passwordSetting/resetPassword'),
    },
    {
      path: '/pickup',
      name: 'pickup',
      component: () => import('@/views/pickup/pickup'),
    },
    {
      path: '/pickupList',
      name: 'pickupList',
      component: () => import('@/views/pickupList/pickupList'),
    },
    {
      path: '/pickupDetails',
      name: 'pickupDetails',
      component: () => import('@/views/pickupDetails/pickupDetails'),
    },
    {
      path: '/systemSetting',
      name: 'systemSetting',
      component: () => import('@/views/systemSetting/systemSetting'),
    },
    {
      path: '/newsList',
      name: 'newsList',
      component: () => import('@/views/newsList/newsList'),
    },
    {
      path: '/pickupConfirm',
      name: 'pickupConfirm',
      component: () => import('@/views/pickupConfirm/pickupConfirm'),
    },
    {
      path: '/pickupEdit',
      name: 'pickupEdit',
      component: () => import('@/views/pickupEdit/pickupEdit'),
    },
    {
      path: '/pickupCancel',
      name: 'pickupCancel',
      component: () => import('@/views/pickupCancel/pickupCancel'),
    },
    {
      path: '/userDetails',
      name: 'userDetails',
      component: () => import('@/views/userDetails/userDetails'),
    },
    {
      path: '/searchPlace',
      name: 'searchPlace',
      component: () => import('@/views/searchPlace/searchPlace'),
    },
    {
      path: '/placeList',
      name: 'placeList',
      component: () => import('@/views/placeList/placeList'),
    },
    {
      path: '/placeDetails',
      name: 'placeDetails',
      component: () => import('@/views/placeDetails/placeDetails'),
    },
    {
      path: '/pickupCancelCompletion',
      name: 'pickupCancelCompletion',
      component: () => import('@/views/pickupCancelCompletion/pickupCancelCompletion'),
    },
    {
      path: '/question',
      name: 'question',
      component: () => import('@/views/question/question'),
    },
    {
      path: '/termOfUse',
      name: 'termOfUse',
      component: () => import('@/views/termOfUse/termOfUse'),
    },
  ]
}];
export default new Router({
  mode: 'history',
  base: '',
  routes: routerMap
})
