import {
  Message
} from 'element-ui'

export default {
  warning(msg) {
    Message({
      showClose: true,
      dangerouslyUseHTMLString: true,
      message: msg,
      duration:4000,
      type: 'warning'
    });
  },
  error(msg) {
    Message({
      showClose: true,
      dangerouslyUseHTMLString: true,
      message: msg,
      duration:4000,
      type: 'error'
    });
  },
  success(msg) {
    Message({
      showClose: true,
      dangerouslyUseHTMLString: true,
      message: msg,
      duration:4000,
      type: 'success'
    });
  }
}
