import {
  default as request
} from '../../utils/request'
import api from '../api'
const pickup = {
  state: {
    pickupModel: 'register',
    pickupList: [],
    deliveryList: [{
      placeId: '',
      placeName: '',
      postNo: '',
      address1: '',
      address2: '',
      telNo: '',
      marketDivision: '',
      deliverRangeStart: '',
      deliverRangeEnd: ''
    }],
    pickupDestinationOpt: [],
    deliveryDestinationOpt: [],
    marketDivision: '',
    goodsList: [{
      itemId: '',
      itemCode: '',
      itemName: '',
      unit: ''
    }],
    goodsCodeOpt: [],
    pickupPostData: {
      requestDay: '', //登録日
      pickupDay: '', //集荷希望日
      deliveryDay: '', //配送希望日
      pickupToId: '', //集荷先
      pickupToName: '', //集荷先
      pickupToAddress: '', //集荷先住所
      pickupToTelNo: '', //集荷先電話番号
      deliveryToId: '', //配送先
      deliveryToName: '', //配送先
      deliveryToAddress: '', //配送先住所
      deliveryToTelNo: '', //配送先電話番号
      itemList: [], //商品リスト
      shipperName: ''
    },
    pickupEditPostData: {
      requestDay: '', //登録日
      pickupDay: '', //集荷希望日
      deliveryDay: '', //配送希望日
      pickupToId: '', //集荷先
      pickupToName: '', //集荷先
      pickupToAddress: '', //集荷先住所
      pickupToTelNo: '', //集荷先電話番号
      deliveryToId: '', //配送先
      deliveryToName: '', //配送先
      deliveryToAddress: '', //配送先住所
      deliveryToTelNo: '', //配送先電話番号
      itemList: [], //商品リスト
      shipperName: '',
      pickupRequestId: '',
      pickupRequestNo: '',
    },
    pickupListData: [],
    pickupDetails: {},
    pickupStatus:'',
    nowDate:''
  },
  mutations: {
    updatePickupStatus(state, data) {
      state.pickupStatus = data
      return state
    },
    updatePickupModel(state, model) {
      state.pickupModel = model
      return state
    },
    updatePickupList(state, pickupList) {
      state.pickupList = pickupList
      return state
    },
    updateDeliveryList(state, deliveryList) {
      state.deliveryList = deliveryList;
      return state
    },
    updatePickupDestinationOpt(state, arr) {
      state.pickupDestinationOpt = arr
      return state
    },
    updateDeliveryDestinationOpt(state, arr) {
      state.deliveryDestinationOpt = arr
      return state
    },
    updateMarketDivision(state, str) {
      state.marketDivision = str
      return state
    },
    updateGoodsList(state, arr) {
      state.goodsList = arr
      return state
    },
    updateGoodsCodeOpt(state, arr) {
      state.goodsCodeOpt = arr
      return state
    },
    updatePickPostData(state, data) {
      state.pickupPostData = data
      return state
    },
    updatePickEditPostData(state, data) {
      state.pickupEditPostData = data
      return state
    },
    updatePickupListData(state, data) {
      state.pickupListData = data
      return state
    },
    updatePickupDetails(state, data) {
      state.pickupDetails = data
      return state
    },
    ServerTime(state, data) {
      state.nowDate = data
      return state
    }
  },
  actions: {
    getPickupListAction({
      commit
    }) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC03,
          method: "get"
        }).then(res => {
          const arr = []
          if (res.data.data.place) {
            let obj = {}
            res.data.data.place.find((item) => {
              obj = {
                label: item.placeName,
                value: item.placeId
              }
              arr.push(obj)
            })
          }
          commit('updatePickupList', res.data.data.place)
          commit('updatePickupDestinationOpt', arr)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
    getTimeForNow({
                            commit
                          }) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AP06,
          method: "get"
        }).then(res => {
          commit('ServerTime',res.data.data.serverTime)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
    getDeliveryListAction({
      commit
    }) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC04,
          method: "get"
        }).then(res => {
          const arr = []
          if (res.data.data.place) {
            let obj = {}
            res.data.data.place.find((item) => {
              obj = {
                label: item.placeName,
                value: item.placeId
              }
              arr.push(obj)
            })
          }
          commit('updateDeliveryList', res.data.data.place)
          commit('updateDeliveryDestinationOpt', arr)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
    getGoodsListAction({
      commit
    }) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC05,
          method: "get"
        }).then(res => {
          const opt = []
          if (res.data.data.item) {
            let obj = {}
            res.data.data.item.find((item) => {
              obj = {
                label: item.itemName + '(' + item.itemCode + ')',
                value: item.itemId
              }
              opt.push(obj)
            })
          }
          commit('updateGoodsList', res.data.data.item)
          commit('updateGoodsCodeOpt', opt)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
    pickupRegisterAction({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        const paramData = {
          requestDay: '', //登録日
          pickupDay: '', //集荷希望日
          deliveryDay: '', //配送希望日
          pickupToId: '', //集荷先
          pickupToTelNo: '', //集荷先電話番号
          deliveryToId: '', //配送先
          deliveryToTelNo: '', //配送先電話番号
          itemList: [] ,//商品リスト
          shipperName:''
        }
        const postData = state.pickupPostData
        paramData.requestDay = postData.requestDay
        paramData.pickupDay = postData.pickupDay
        paramData.deliveryDay = postData.deliveryDay
        paramData.pickupToId = postData.pickupToId
        paramData.pickupToTelNo = postData.pickupToTelNo
        paramData.deliveryToId = postData.deliveryToId
        paramData.deliveryToTelNo = postData.deliveryToTelNo
        paramData.shipperName = postData.shipperName
        postData.itemList.find((item) => {
          const obj = {
            itemId: item.itemId,
            quantity: item.quantity,
            pickupItemName: item.pickupItemName
          }
          paramData.itemList.push(obj)
        })
        request({
          url: api.AP03,
          method: "post",
          data: paramData,
          headers: {
            'content-type': 'application/json'
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    pickupEditAction({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        const paramData = {
          requestDay: '', //登録日
          pickupDay: '', //集荷希望日
          deliveryDay: '', //配送希望日
          pickupToId: '', //集荷先
          pickupToAddress: '', //集荷先住所
          pickupToTelNo: '', //集荷先電話番号
          deliveryToId: '', //配送先
          deliveryToAddress: '', //配送先住所
          deliveryToTelNo: '', //配送先電話番号
          itemList: [], //商品リスト
          pickupRequestId: '',
          shipperName:''
        }
        const postData = state.pickupEditPostData
        paramData.requestDay = postData.requestDay
        paramData.pickupDay = postData.pickupDay
        paramData.deliveryDay = postData.deliveryDay
        paramData.pickupToId = postData.pickupToId
        paramData.pickupToAddress = postData.pickupToAddress
        paramData.pickupToTelNo = postData.pickupToTelNo
        paramData.deliveryToId = postData.deliveryToId
        paramData.deliveryToAddress = postData.deliveryToAddress
        paramData.deliveryToTelNo = postData.deliveryToTelNo
        paramData.shipperName = postData.shipperName
        paramData.pickupRequestId = postData.pickupRequestId
        postData.itemList.find((item) => {
          const obj = {
            itemId: item.itemId,
            quantity: item.quantity,
            pickupItemName: item.pickupItemName
          }
          if (item.branchNo !== undefined) {
            obj['branchNo'] = item.branchNo
          }
          paramData.itemList.push(obj)
        })
        request({
          url: api.AP04,
          method: "post",
          data: paramData,
          headers: {
            'content-type': 'application/json'
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    pickupDetailsAction({
      commit,
      state
    }, pickupRequestId) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AP02,
          method: "get",
          params: {
            'pickupRequestId': pickupRequestId
          },
        }).then(res => {
          commit('updatePickupDetails', res.data.data.details)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
    getPickupListDataAction({
      commit,
      state
    }, payload) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AP01,
          method: "get",
          params: {
            'year': payload.year,
            'month': payload.month
          },
        }).then(res => {
          commit('updatePickupListData', res.data.data.pickupList)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    pickupCancelAction({
      commit,
      state
    }, pickupRequestId) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AP05,
          method: "post",
          data: {
            'pickupRequestId': pickupRequestId
          },
          headers: {
            'content-type': 'application/json'
          }
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

  }
}
export default pickup
