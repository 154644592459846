import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import message from './utils/message'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/permission'
import Router from 'vue-router'
import qs from 'qs';
import echarts from 'echarts';
import locale from 'element-ui/lib/locale/lang/ja'
import i18n from './locales/index'
Vue.use(ElementUI, {
  locale,
  size: 'large'
})
Vue.use(Vant, {
  size: 'small'
});
Vue.config.productionTip = false
Vue.prototype.$qs = qs
Vue.prototype.$message = message
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
//同じルートが異常にジャンプします。
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
});
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: {
    App
  },
  template: '<App/>'
});
