import { default as request } from '../../utils/request'
import api from '../api'
const news ={
  state:{
    newsList:[
      {
        newsId:'',
        title:'',
        content:'',
        start:'',
        end:''
      }
    ],
    news:{
     title:'',
     created:'',
     content:''
    }
  },
  mutations:{
    updateNews(state,newsList){
      state.newsList = newsList;
      return state;
    }
  },
  actions:{
    getNewsListAction({commit}){
      return new Promise((resolve, reject) => {
        request({
          url: api.AC01,
          method: "get"
        }).then(res => {
          commit('updateNews',res.data.data.news)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    getNewsDetailsAction({commit},newsId){
      return new Promise((resolve, reject) => {
        request({
          url: api.AC02,
          method: "get",
          params: newsId
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },
  }
}
export default news
