import {default as request} from '../../utils/request'

const sign = {
  state: {
  },
  mutations: {
  },
  actions: {

  }
}
export default sign
