<template>
  <section>
    <transition name="van-fade" mode="out-in">
       <router-view :key="$route.fullPath"></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppBase",
  computed: {
  },
  mounted() {}
};
</script>
