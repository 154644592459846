import {
  default as request
} from '../../utils/request'
import api from '../api'

const common = {
  state: {
    placeList: [],
    placeDetails: {}
  },
  mutations: {
    updatePlaceList(state, data) {
      state.placeList = data
      return state
    },
    updatePlaceDetails(state, data) {
      state.placeDetails = data
      return state
    },
  },
  actions: {
    searchPlaceAction({
      commit,
      state
    }, paramData) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC06,
          method: "get",
          params: paramData
        }).then(res => {
          commit('updatePlaceList', res.data.data.Place)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    placeDetailsAction({
      commit,
      state
    }, placeCode) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC07,
          method: "get",
          params: {
            'placeCode': placeCode
          }
        }).then(res => {
          commit('updatePlaceDetails', res.data.data.Place)
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    placeAddAction({
      commit,
      state
    }, paramData) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC08,
          method: "post",
          data: paramData,
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    getTermOfUseAction({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC09,
          method: "get",
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

    contactUsAction({
      commit,
      state
    }, paramData) {
      return new Promise((resolve, reject) => {
        request({
          url: api.AC10,
          method: "post",
          params: paramData,
        }).then(res => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
      }).catch((e) => {});
    },

  }
}
export default common
