<template>
  <div>
    <div class="header">
      <div id="headerTitle" style="font-weight: 600;" v-if="$store.state.app.pathName==='pc'">
        {{passwordTitle}}
      </div>
      <div id="headerTitle" v-else>
        {{passwordTitle}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Header",
    data() {
      return {
        passwordTitle: ''
      }
    },
    computed: {},
    methods: {},
    created() {
      if (this.$route.fullPath === '/mailSend') {
        this.passwordTitle = 'パスワード変更'
      } else {
        this.passwordTitle = 'パスワード再設定'
      }
    }
  }
</script>
<style scoped>
  .header {
    margin-bottom: 10px;
  }

  #headerTitle {
    background-color: #00C07D;
    color: #eaeaea;
    font-size: 20px;
    text-align: center;
    height: 70px;
    line-height: 70px;
    width: 100%;
    overflow: hidden;
  }
</style>
