import router from './router'
import {
  Toast
} from "vant";

const whiteList = ["/login", "404", "500", "/mailSend","/resetPassword"];
router.beforeEach((to, from, next) => {
  Toast.loading({
    duration: 0,
    forbidClick: true,
    overlay: true,
    message: 'Loading...',
  });
  if (localStorage.getItem("access_token") !== "null" && localStorage.getItem("access_token") != null) {
    if (to.path) {
      if (to.path === '/login') {
        next({
          path: '/'
        });
        Toast.clear()
      } else {
        next();
        Toast.clear()
      }
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    next();
    Toast.clear()
  } else {
    next('/login');
    Toast.clear()
  }
})
router.afterEach(() => {
  Toast.clear()
});
