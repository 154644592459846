const api = {
    "AU01":"user/login",
    "AU02":"user/changePassword",
    "AU03":"mail/resetRequest",
    "AU04":"user/resetPassword",
    "AU05":"user/details",
    "AU06":"user/logout",
    "AC01":"common/newsList",
    "AC02":"common/news",
    "AC03":"common/pickupAddressList",
    "AC04":"common/deliverAddressList",
    "AC05":"common/itemList",
    "AC06":"common/searchPlace",
    "AC07":"common/placeDetails",
    "AC08":"common/addPlace",
    "AC09":"common/termOfUse",
    "AC10":"common/contactUs",
    "AP01":"pickup/list",
    "AP02":"pickup/details",
    "AP03":"pickup/request",
    "AP04":"pickup/edit",
    "AP05":"pickup/cancel",
    "AP06":"common/getServerTime"
  }
export default api
