import {
  default as request
} from '../../utils/request'

const app = {
  state: {
    company: '',
    department: '',
    userId: '',
    title: 'ソノリク集荷・配送サービス',
    pathName: ''
  },
  mutations: {
    updatePathName(state, data) {
      state.pathName = data.pathName
      return state
    },
    updateUserInfo(state, data) {
      state.company = data.company
      state.department = data.department
      return state
    },
    updateAppTitle(state, title) {
      state.title = title
      return state
    }
  },
  actions: {}
}
export default app
