<template>
  <div class="footer" >
      <p>Powered by SONORIKU</p>
  </div>
</template>

<script>
  export default {
    name: 'Footer-company',
    data() {
      return {}
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    position: relative;
    background-color: #00C07D ;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 13px;
    text-align: center;
    color: #f7f7f7;
    z-index: 2;
    height: 45px;
  }
</style>
