<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        screenWidth: '',
        baseURL: '',
        pathName: '',
        //ログイン情報
        loginForm: {
          username: '',
          password: '',
        },
      }
    },
    computed: {
      //ログイン状態を更新する
      show: {
        get() {
          return this.$store.getters.isLogin
        }
      }
    },
    methods: {
      isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        return flag;
      },
      baseSetting() {
        let turnName;
        if (this.isMobile()) {
          turnName = "mobile"
          this.pathName = turnName
        } else {
          turnName = "pc"
          this.pathName = turnName
        }
        window.localStorage.setItem("pathName", this.pathName)
        this.$store.commit('updatePathName', {
          'pathName': this.pathName
        })
      },
      changePathSetting() {
        let turnName;
        if (this.isMobile()) {
          turnName = "mobile"
        } else {
          turnName = "pc"
        }
        if (this.pathName == turnName) {} else {
          this.pathName = turnName
          window.localStorage.setItem("pathName", this.pathName)
          this.$store.commit('updatePathName', {
            'pathName': this.pathName
          })
        }
      }
    },
    mounted() {
      this.baseSetting();
      let _this = this;
      window.onresize = () => {
        return (() => {
          _this.changePathSetting()
        })()
      }
    },
    created() {
      if (localStorage.getItem("store")) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem("store"))))
      }
      window.addEventListener("beforeunload", () => {
        localStorage.setItem("store", JSON.stringify(this.$store.state))
      })
    },
    watch: {},
  }
  window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    })
    var lastTouchEnd = 0;
    document.addEventListener('touchend', function(event) {
      var now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, false);
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault();
    });
  }
</script>

<style>
  #app {
    height: 100%;
    width: 100%;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    background-color: #ffffff;
  }

  html,
  body {
    margin: 0;
    height: 100%;
    width: 100%;
    color: #000000;
    font-size: 16px;
    touch-action: none;
  }

  .fillcontain {
    height: 100%;
    width: 100%;
  }
</style>
<style lang="scss">
  .el-scrollbar {
    >.el-scrollbar__bar {
      opacity: 1 !important;
    }
  }
</style>
<style src="./assets/style.css" lang="css"></style>
<style src="./assets/mobileStyle.css" lang="css"></style>
<style src="./assets/pcStyle.css" lang="css"></style>
