<template>
  <div>
    <div class="header">
      <div id="headerTitle">{{this.$store.state.app.title}}</div>
      <div class="content">
        こんにちは、{{userNameInfo}} 様
      </div>
      <div>
        <hr class="bottomLine" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Header",
    data() {
      return {
        userNameInfo: ''
      }
    },
    computed: {},
    methods: {},
    created() {
      const company = this.$store.state.app.company
      const department = this.$store.state.app.department
      if (company === '' || company === null) {
        this.userNameInfo = localStorage.getItem('userNameInfo')
      } else {
        this.userNameInfo = company + department
      }
    }
  }
</script>
<style scoped>
  .header {
    margin-bottom: 10px;
  }

  #headerTitle {
    background-color: #00C07D;
    color: #eaeaea;
    font-size: 20px;
    text-align: center;
    height: 70px;
    line-height: 70px;
    width: 100%;
    overflow: hidden;
  }

  .bottomLine {
    margin-top: 0px;
    height: 3px;
    background-color: #00C07D;
    border: none;
  }

  .content {
    width: 100%;
    height: 40px;
    position: relative;
    text-align: center;
    background-color: #96E47F;
    color: #efefef;
    line-height: 40px;
    font-size: 16px;
  }
</style>
