<template>
  <div id="full">
    <Header
      v-if="$store.state.app.pathName==='mobile' && $route.fullPath !=='/login'　&& $route.fullPath !=='/mailSend' && $route.fullPath.indexOf('/resetPassword') == -1">
    </Header>
    <HeaderPC v-if="$store.state.app.pathName==='pc' && $route.fullPath ==='/home'"></HeaderPC>
    <HeaderForPassword v-if="$route.fullPath ==='/mailSend' || $route.fullPath.indexOf('/resetPassword') != -1" />
    <HeaderMenuPC v-if="$store.state.app.pathName==='pc' && $route.fullPath !=='/home' && $route.fullPath !=='/login'
    && $route.fullPath !=='/mailSend' && $route.fullPath.indexOf('/resetPassword') == -1"></HeaderMenuPC>
    <AppBase class="main"></AppBase>
    <FooterCompany
      v-if="($store.state.app.pathName==='mobile' && $route.fullPath === '/login' || $route.fullPath === '/home') || ($store.state.app.pathName==='pc' && $route.fullPath !== '/login')">
    </FooterCompany>
    <FooterMenuCompany
      v-if="($store.state.app.pathName==='mobile' && $route.fullPath !== '/login' && $route.fullPath !== '/home')">
    </FooterMenuCompany>
    <FooterCompanyPC v-if="$store.state.app.pathName==='pc' && $route.fullPath === '/login'"></FooterCompanyPC>
  </div>
</template>

<script>
  import AppBase from "./AppBase";
  import Header from "./Header";
  import HeaderPC from "./Header-pc";
  import HeaderMenuPC from "./Header-menu-pc";
  import HeaderForPassword from "./Header-forPassword"
  import FooterCompany from "./Footer-company";
  import FooterCompanyPC from "./Footer-company-pc";
  import FooterMenuCompany from "./Footer-menu-company";

  export default {
    name: "layout",
    data() {
      return {}
    },
    components: {
      AppBase,
      Header,
      HeaderPC,
      HeaderMenuPC,
      HeaderForPassword,
      FooterCompany,
      FooterCompanyPC,
      FooterMenuCompany
    },
    methods: {},
    computed: {},
    mounted() {}
  };
</script>


<style lang='scss' scoped>
  #full {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 100%;
  }

  .main {
    flex: 1;
    overflow-x: hidden;
    padding-bottom: 30px;
  }
</style>

