<template>
  <div>
    <div id="headerTitle">
      <div class="leftText"> {{this.$store.state.app.title}}</div>
      <div class="rightText">こんにちは、{{userNameInfo}} 様</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Header-pc",
    data() {
      return {
        userNameInfo: ''
      }
    },
    computed: {},
    methods: {},
    created() {
      const company = this.$store.state.app.company
      const department = this.$store.state.app.department
      if (company === '' || company === null) {
        this.userNameInfo = localStorage.getItem('userNameInfo')
      } else {
        this.userNameInfo = company + department
      }
    }
  }
</script>
<style scoped>
  #headerTitle {
    margin-bottom: 20px;
    background-color: #00C07D;
    color: #eaeaea;
    font-size: 20px;
    height: 70px;
    line-height: 70px;
    width: 100%;
    overflow: hidden;
  }

  .leftText {
    float: left;
    font-weight: 600;
    margin-left: 30px;
  }

  .rightText {
    float: right;
    margin-right: 30px;
  }
</style>
