import axios from 'axios'
import router from '../router/index'
import store from "../store";
import {
  Loading
} from 'element-ui'
import message from './message';
import {
  Toast
} from 'vant'

const service = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 15000
});
// request interceptors
service.interceptors.request.use(config => {
  Toast.loading({
    duration: 0,
    forbidClick: true,
    overlay: true,
    message: 'Loading...',
  });
  const access_token = localStorage.getItem("access_token")
  if (access_token) {
    if (access_token !== 'null' && access_token != null) {
      config.headers['token'] = access_token + '.front'
    }
  }
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
});

// respone interceptors
service.interceptors.response.use(
  response => {
    Toast.clear()
    /**
     * リターンcode値が0以外の場合、エラーメッセージを処理する
     */
    const res = response;
    if (res.data.code > 0) {
      message.error(res.data.msg);
      if (res.data.code === 401) {
        localStorage.clear();
        location.reload()
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    Toast.clear();
    var status = error.response.status;
    if (String(status).substr(0, 1) === "4" && status !== 401 && status !== 403 && status !== 404) {
      router.push({
        path: '/errorPage/' + status
      });
      return Promise.reject('error')
    }
    if (status === 401) {
      localStorage.clear();
      location.reload()
    } else if (status === 403) {
      var msg = error.response.headers["login-err"];
      if (!msg) {
        msg = "Incorrect account password。"
      }
      Toast({
        message: msg,
        type: 'error',
      })
      localStorage.clear()
    } else if (status === 504) {
      Toast({
        message: 'サーバーガタイムアウトしました。サーバー起動確認の上に再试行してください。',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    } else if (status === 500) {
      message.error(error.response.data.message || error.message);
      return Promise.reject(error)
    } else if (String(status).substr(0, 1) === "5") {
      router.push({
        path: '/500'
      });
      return Promise.reject(error)
    } else {
      Toast({
        message: error.message,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(error)
    }
  }
);

export default service
