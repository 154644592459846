<template>
  <div style="width:100%;height:105px;position:relative; background-color: #00C07D ;">
    <div style="width:100%;height:48px;margin-top: 15px;  position: absolute;  top: 0; left: 0; bottom: 0; right: 0;">
      <div class="footer">
        <div class="costList1" style="margin-left: 20px;" @click="choiceState('home')">
          <img class="img" :src="img1" />
        </div>
        <div class="costList1" @click="choiceState('pickup')">
          <img class="img" :src="img2" />
        </div>
        <div class="costList1" @click="choiceState('pickupList')">
          <img class="img" :src="img3" />
        </div>
        <div class="costList1" style="margin-right:20px;" @click="choiceState('systemSetting')">
          <img class="img" :src="img4" />
        </div>
      </div>
      <div style="color: white;text-align: center;font-size: 12px;margin-top: 60px;">Powered by SONORIKU</div>
    </div>
  </div>
</template>

<script>
  import img1Url from "../../assets/images/sonoriku/SP_footer_icon01.png"
  import img2Url from "../../assets/images/sonoriku/SP_footer_icon02.png"
  import img3Url from "../../assets/images/sonoriku/SP_footer_icon03.png"
  import img4Url from "../../assets/images/sonoriku/SP_footer_icon04.png"

  export default {
    name: 'Footer',
    data() {
      return {
        img1: img1Url,
        img2: img2Url,
        img3: img3Url,
        img4: img4Url
      }
    },
    methods: {
      choiceState(path) {
        switch (path) {
          case 'home':
            this.$store.commit('updateAppTitle', 'ソノリク集荷・配送サービス')
            break;
          case 'pickup':
            const pickupPostData = {
              requestDay: '', //登録日
              pickupDay: '', //集荷希望日
              deliveryDay: '', //配送希望日
              pickupToId: '', //集荷先
              pickupToName: '', //集荷先
              pickupToAddress: '', //集荷先住所
              pickupToTelNo: '', //集荷先電話番号
              deliveryToId: '', //配送先
              deliveryToName: '', //配送先
              deliveryToAddress: '', //配送先住所
              deliveryToTelNo: '', //配送先電話番号
              billingDestimation: '1', //請求先
              itemList: [], //商品リスト,
              shipperName: '' //出荷名義
            }
            this.$store.commit('updatePickPostData', pickupPostData)
            this.$store.commit('updateAppTitle', '集荷依頼')
            this.$store.commit('updateMarketDivision', '')
            break;
          case 'pickupList':
            this.$store.commit('updateAppTitle', '集荷依頼一覧')
            break;
          case 'systemSetting':
            this.$store.commit('updateAppTitle', '設定')
            break;
        }
        this.$router.push(path)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .costList1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      align-items: center;
    }
  }

  .ant-carousel>>>.slick-slide {
    text-align: center;
    height: 160px !important;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel>>>.slick-slide h4 {
    color: #fff;
  }

  .img {
    width: 58px;
    height: 48px;
  }

  .company {
    position: relative;
    background-color: #00C07D;
    width: 100%;
    font-size: 6px;
    text-align: center;
    color: #f0f0f0;
  }
</style>
